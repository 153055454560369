<template>
  <section class="s-team-members">
    <div class="outer-wrapper m-our-team">

      <div class="m-news__filters" v-if="showFilters">
        <div class="inner-wrapper">
          <h2 class="m-news__filters__title">Filters</h2>

          <ul class="m-news__filters__list">
            <li class="m-news__filters__list__item" v-for="team in teamOptions" :key="team.id">
              <a @click="activeTeam=team" :class="`m-news__filters__filter ${activeTeam.id == team.id ? 'active' : ''}`">{{team.name}}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="m-news__mobile-filters">
        <div :class="['m-news__mobile-filters__label', {'is-active' : mobileFiltersOpen}]" @click="toggleMobileFilters">
          <span class="label">{{ activeTeam.name }}</span>
          <i class="icon icon--arrow--down--blue "></i>
        </div>
        <div class="m-news__mobile-filters__list" ref="mobileFilters">
          <ul>
            <li class="m-news__mobile-filters__list-item" v-for="team in teamOptions" :key="team.id">
              <a @click="activeTeam=team; toggleMobileFilters()" :class="`m-news__mobile-filters__filter ${activeTeam.id == team.id ? 'active' : ''}`">{{team.name}}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="inner-wrapper" :style="!showFilters ? 'padding-top:120px' : ''">
        <div class="m-our-team__grid">
          <div class="m-our-team__member" v-for="teamMember in teamMembers" :key="teamMember.id" @click="selectTeamMember(teamMember)">
            <div class="m-our-team__member__image">
              <img :src="teamMember.image" alt="" v-if="teamMember.image" />

              
            </div>
            <div class="m-our-team__member__details">
              <h5 class="name">{{teamMember.name }}</h5>
              <p class="title">{{teamMember.role}}</p>
            </div>
          </div>
        </div>
        
        <div class="m-our-team__show-more" v-if="hasMore">
          <button type="button" class="button button--hollow--blue" @click="fetchMore">Show More</button>
        </div>
      </div>
    </div>

    <div :class="['m-our-team__modal', {'is-visible': modalVisible}]" @click="dismissProfile">

      <TeamMemberProfile 
        :activeTeamMember="activeTeamMember" 
        :visible="profileVisible" 
        v-on:dismissProfile="dismissProfile"
      />
    </div>


    <svg width="0" height="0" viewBox="0 0 111 108" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <clipPath id="memberClip">
          <polygon transform="translate(55.367033, 54.023076) rotate(-11.000000) translate(-55.367033, -54.023076) " points="82.0748663 4.7805804 26.075628 6.27192325 -0.632966622 55.5136571 28.6576771 103.265571 84.6569155 101.774229 111.367033 52.5324948"></polygon>
        </clipPath>
      </defs>
    </svg>

    <svg width="0" height="0" viewBox="0 0 133 130" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <clipPath id="memberClipBig">
          <polygon transform="translate(66.428350, 65.000000) rotate(-11.000000) translate(-66.428350, -65.000000) " points="98.5557187 5.7652494 31.1931515 7.55921456 -0.93513367 66.7930489 34.2991484 124.234751 101.661716 122.440785 133.791833 63.2069511"></polygon>
        </clipPath>
      </defs>
    </svg>
  </section>
</template>


<script>
import Api from "../utils/api";
import TeamMemberProfile from './team-member-profile';
import _ from 'lodash';

export default {
  props: ["teams", "teamMember"],
  components: {
    TeamMemberProfile
  },
  data() {
    return {
      showFilters: true,
      teamMembers: [],
      activeTeam: {id: null, name: "Show All"},
      activeTeamMember: {},
      offset: 0,
      limit: 100,
      hasMore: false,
      loading: false,
      modalVisible: false,
      profileVisible: false,
      mobileFiltersOpen: false
    }
  },

  computed: {
    teamOptions() {
      let teamsSorted = _.sortBy(this.teams, 'name')
      return [{id: null, name: "Show All"}, ..._.sortBy(teamsSorted, 'position')]
    },
  },

  mounted() {
    this.fetch();
    if (this.teamMember) {
      this.selectTeamMember(this.teamMember)
    }
  },  

  watch: {
    activeTeam() {
      this.fetch()
    }
  },

  methods: {
    toggleMobileFilters() {
      const menu = this.$refs.mobileFilters;
      const menuHeight = menu.scrollHeight;
      
      if(!this.mobileFiltersOpen) {
        menu.style.maxHeight = menuHeight + 'px';
        this.mobileFiltersOpen = true;
      } else {
        menu.style.maxHeight = 0;
        this.mobileFiltersOpen = false;
      }
    },

    fetch() {
      this.loading = true
      this.hasMore = false
      Api.get('/team_members', { params: { 
        team_id: this.activeTeam.id,
        offset: 0,
        limit: this.limit,
      }})
        .then((response) => {
          this.teamMembers = response.data.team_members
          this.offset = 1
          this.hasMore = response.data.has_more
          this.loading = false
        }).catch((e) => {
          this.loading = false;
          console.log(e.response)
          alert("Sorry, something went wrong")
        });
    },

    fetchMore() {
      this.offset += this.limit;
      Api.get('/team_members', { params: { 
        team_id: this.activeTeam.id,
        offset: this.offset,
        limit: this.limit
      }})
        .then((response) => {
          this.teamMembers = [...this.teamMembers, ...response.data.team_members]
          this.hasMore = response.data.has_more
          this.loading = false
        }).catch((e) => {
          this.loading = false;
          console.log(e.response)
          alert("Sorry, something went wrong")
        });      
    },

    selectTeamMember(teamMember) {
      this.activeTeamMember = teamMember;
      window.history.replaceState({}, null, `/team-members/${teamMember.slug}`)
      this.modalVisible = true;
      this.profileVisible = true;
    },

    dismissProfile() {
      this.modalVisible = false;
      this.profileVisible = false;
      window.history.replaceState({}, null, "/team-members")
      setTimeout(() => {
        this.activeTeamMember = {};  

      }, 500);
    }
  }
}
</script>