<template>
  <div :class="['modal', {'is-visible': visible}]"></div>
</template>

<script>
import { EventBus } from '../packs/event-bus';
export default {
  data() {
    return {
      visible: false
    }
	},
	mounted() {
		EventBus.$on('toggleModal', (payload) => {
			if(payload.visibility === 'show') {
				this.visible = true;
			} else {
				this.visible = false;
			}
		})
	}
}
</script>

<style lang="scss" scoped>
	.modal {
		position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 400;
    opacity: 0;
    pointer-events: none;
    background: #16152E;
    transition: opacity .4s ease-out;

    &.is-visible {
      opacity: 0.7;
    }
	}
</style>