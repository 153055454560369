<template>
    <share-network
      network="twitter"
      :url="url"
      :title="title"
      twitter-user="diaceutics"
    >
    <svg width="22px" height="19px" viewBox="0 0 22 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Path</title>
        <g id="Social-Icons-on-Articles" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="News-Article" transform="translate(-204.000000, -1495.000000)" fill="#6C1C73">
                <path d="M226,1498.628 L224,1498.128 L225,1496.128 L222.536,1496.828 C221.228505,1495.60821 219.320871,1495.28275 217.682895,1496.00002 C216.04492,1496.71728 214.990341,1498.33989 215,1500.128 L215,1501.128 C211.461,1501.858 208.366,1499.928 205.5,1496.628 C205,1499.29467 205.5,1501.29467 207,1502.628 L204,1502.128 C204.405,1504.197 205.362,1505.828 208,1506.128 L205.5,1507.128 C206.5,1509.128 208.066,1509.438 210.5,1509.628 C208.60692,1510.98064 206.326029,1511.68245 204,1511.628 C216.755,1517.297 224,1508.964 224,1501.628 L224,1500.3 L226,1498.628 Z" id="Path"></path>
            </g>
        </g>
    </svg>
  </share-network>
</template>

<script>
export default {
  props: {
    url: { type: String, required: true },
    title: { type: String, required: true }
  }
}
</script>