<template>
  <div class="m-card-carousel">
    <template v-if="data.length >= 3">
      <flickity ref="flickity" :options="flickityOptions">
        <template v-for="team in data">
          <a :href="team.page_url" :key="team.id" :class="team.exodus_team_type.colour" class="m-exodus-teams__teams__item team-card">
            <div class="team-card__top">
              <div class="team-card__header">
                <div class="team-card__type">{{ team.exodus_team_type.name }}</div>
                <div class="team-card__name">{{ team.name }}</div>
              </div>
              <div class="team-card__location">{{ team.exodus_team_location.name }}</div>
              <div class="team-card__meeting">{{ team.meeting }}</div>
              <div class="team-card__leaders">{{ team.leaders }}</div>
            </div>
            <div class="team-card__bottom">
              <div class="team-card__dates">
                <i></i>
                {{ team.dates }}
              </div>
              <div class="team-card__destination">
                <i></i>
                {{ team.location }}
              </div>
            </div>
          </a>
        </template>
      </flickity>
      <template v-if="data.length > 1">
        <button
          @click="previous()"
          class="m-card-carousel__button m-card-carousel__button--prev"
        >
          <i class="icon icon--card-carousel--prev"></i>
        </button>

        <button
          @click="next()"
          class="m-card-carousel__button m-card-carousel__button--next"
        >
          <i class="icon icon--card-carousel--next"></i>
        </button>
      </template>
    </template>
    <template v-else>
      <div class="static-carousel">
        <template v-for="team in data">
          <a :href="team.page_url" :key="team.id" :class="team.exodus_team_type.colour" class="m-exodus-teams__teams__item team-card">
            <div class="team-card__top">
              <div class="team-card__header">
                <div class="team-card__type">{{ team.exodus_team_type.name }}</div>
                <div class="team-card__name">{{ team.name }}</div>
              </div>
              <div class="team-card__location">{{ team.exodus_team_location.name }}</div>
              <div class="team-card__meeting">{{ team.meeting }}</div>
              <div class="team-card__leaders">{{ team.leaders }}</div>
            </div>
            <div class="team-card__bottom">
              <div class="team-card__dates">
                <i></i>
                {{ team.dates }}
              </div>
              <div class="team-card__destination">
                <i></i>
                {{ team.location }}
              </div>
            </div>
          </a>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import Flickity from "vue-flickity";

export default {
  props: {
    data: Array,
  },
  components: {
    Flickity,
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },
    previous() {
      this.$refs.flickity.previous();
    },
  },
  mounted() {
    // This is to size all cards to the same size
    this.$nextTick(() => {
      document.querySelectorAll(".team-card").forEach(e => e.style.height = '100%')
    })
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        draggable: true,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        groupCells: true,
        imagesLoaded: true,
        percentPosition: false,
        // adaptiveHeight: true,
        // setGallerySize: false
      },
    };
  },

  computed: {
    filteredCards() {
      return this.cards.filter((test) => test);
    },
  },
};
</script>