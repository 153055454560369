<template>
    <share-network
      network="linkedIn"
      :url="url"
      :popup="{width: 500, height: 500}"
    >
    <svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Social-Icons-on-Articles" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="News-Article" transform="translate(-262.000000, -1492.000000)" fill="#6C1C73">
                <g id="professional-network-linkedin" transform="translate(262.000000, 1492.000000)">
                    <path d="M5.5,20.5 L0.5,20.5 L0.5,7.5 L5.5,7.5 L5.5,20.5 Z M14.5,11.5 C13.3954305,11.5 12.5,12.3954305 12.5,13.5 L12.5,20.5 L7.5,20.5 L7.5,7.5 L12.5,7.5 L12.5,8.985 C13.6162998,8.03889184 15.0268423,7.5103803 16.49,7.49 C19.452,7.49 21.5,9.69 21.5,13.845 L21.5,20.5 L16.5,20.5 L16.5,13.5 C16.5,12.3954305 15.6045695,11.5 14.5,11.5 Z M5.5,3 C5.5,4.38071187 4.38071187,5.5 3,5.5 C1.61928813,5.5 0.5,4.38071187 0.5,3 C0.5,1.61928813 1.61928813,0.5 3,0.5 C3.66304122,0.5 4.29892601,0.763392101 4.76776695,1.23223305 C5.2366079,1.70107399 5.5,2.33695878 5.5,3 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
  </share-network>
</template>

<script>
export default {
  props: {
    url: {type: String, required: true}
  }
}
</script>