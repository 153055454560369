<template>
  <section class="s-team-members-module">
    <div class="outer-wrapper m-team-members-module">

      <div class="inner-wrapper">
        <div class="m-our-team__grid">
          <div class="m-our-team__member" v-for="teamMember in teamMembers" :key="teamMember.id" @click="selectTeamMember(teamMember)">
            <div class="m-our-team__member__image">
              <img :src="teamMember.image" alt="" v-if="teamMember.image" />              
            </div>
            <div class="m-our-team__member__details">
              <h5 class="name">{{teamMember.name }}</h5>
              <p class="title">{{teamMember.role}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="['m-our-team__modal', {'is-visible': modalVisible}]" @click="dismissProfile">
      <TeamMemberProfile 
        :activeTeamMember="activeTeamMember" 
        :visible="profileVisible" 
        v-on:dismissProfile="dismissProfile"
      />
    </div>

    <svg width="0" height="0" viewBox="0 0 111 108" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <clipPath id="memberClip">
          <polygon transform="translate(55.367033, 54.023076) rotate(-11.000000) translate(-55.367033, -54.023076) " points="82.0748663 4.7805804 26.075628 6.27192325 -0.632966622 55.5136571 28.6576771 103.265571 84.6569155 101.774229 111.367033 52.5324948"></polygon>
        </clipPath>
      </defs>
    </svg>

    <svg width="0" height="0" viewBox="0 0 133 130" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <clipPath id="memberClipBig">
          <polygon transform="translate(66.428350, 65.000000) rotate(-11.000000) translate(-66.428350, -65.000000) " points="98.5557187 5.7652494 31.1931515 7.55921456 -0.93513367 66.7930489 34.2991484 124.234751 101.661716 122.440785 133.791833 63.2069511"></polygon>
        </clipPath>
      </defs>
    </svg>
  </section>
</template>


<script>
import TeamMemberProfile from './team-member-profile';
import _ from 'lodash';

export default {
  props: ["teamMembers"],
  components: {
    TeamMemberProfile
  },
  data() {
    return {
      activeTeamMember: {},
      modalVisible: false,
      profileVisible: false,
    }
  },

  methods: {
    selectTeamMember(teamMember) {
      this.activeTeamMember = teamMember;
      this.modalVisible = true;
      this.profileVisible = true;
    },

    dismissProfile() {
      this.modalVisible = false;
      this.profileVisible = false;
      setTimeout(() => {
        this.activeTeamMember = {};  

      }, 500);
    }
  }
}
</script>