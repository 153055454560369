<template>
  <div>

    <template v-if="showTypes">
      <div class="m-exodus-teams__types">
        <h3>What type of team are you best suited for?</h3>
        <ul class="m-exodus-teams__types__list">
          <li v-for="type in types" :key="type.id" :class="type.colour" class="m-exodus-teams__types__list__item">
            <label class="team__label">
              <div class="team__checkbox">
                <input type="checkbox" name="exodus_team_type_id" v-model="selectedTypes" :value="type.id" />
              </div>
              <div class="team__desc">
                <div class="team__name">{{ type.name }}</div>
                <div class="team__subtitle">{{ type.subtitle }}</div>
              </div>
            </label>
            <a :href="type.page_url" v-if="type.page_url" class="team__link">Learn more</a>
          </li>
        </ul>
      </div>
    </template>
    
    <template v-if="showLocations">
      <div class="m-exodus-teams__locations">
        <h3>Where can you easily travel to for weekly meetings?</h3>
        <ul class="m-exodus-teams__locations__list">
          <li v-for="location in locations" :key="location.id" class="m-exodus-teams__locations__list__item">
            <label class="team__label">
              <div class="team__checkbox">
                <input type="checkbox" name="exodus_team_location_id" v-model="selectedLocations" :value="location.id" />
              </div>
              <div class="team__name">{{ location.name }}</div>
            </label>
          </li>
        </ul>
      </div>
    </template>


    <div class="m-exodus-teams__button" v-if="calculateShowButton"><button @click="onClick" v-if="!showTeams" class="button button--filled--green">Show Teams</button></div>

    <div class="m-exodus-teams__teams" v-if="calculateShowTeams">
      <template v-for="team in filteredTeams">
        <a :href="team.page_url" :key="team.id" :class="team.exodus_team_type.colour" class="m-exodus-teams__teams__item team-card">
          <div class="team-card__top">
            <div class="team-card__header">
              <div class="team-card__type">{{ team.exodus_team_type.name }}</div>
              <div class="team-card__name">{{ team.name }}</div>
            </div>
            <div class="team-card__location">{{ team.exodus_team_location.name }}</div>
            <div class="team-card__meeting">{{ team.meeting }}</div>
            <div class="team-card__leaders">{{ team.leaders }}</div>
          </div>
          <div class="team-card__bottom">
            <div class="team-card__dates">
              <i></i>
              {{ team.dates }}
            </div>
            <div class="team-card__destination">
              <i></i>
              {{ team.location }}
            </div>
          </div>
        </a>
      </template>
    </div>
  </div>
</template>


<script>
import { filter, includes } from 'lodash'
export default {
  props: {
    showTypes: {
      type: Boolean,
      default: false
    },
    showLocations: {
      type: Boolean,
      default: false
    },
    types: {
      type: Array,
      default: function () {
        return [];
      }
    },
    locations: {
      type: Array,
      default: function () {
        return [];
      }
    },
    teams: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },

  data() {
    return {
      selectedTypes: [],
      selectedLocations: [],
      showTeams: false
    }
  },
  methods: {
    onClick() {
      this.showTeams = true;
    },
  },
  watch: {
    selectedTypes() {
      this.onClick()
    },
    selectedLocations() {
      this.onClick()
    },
  },
  computed: {

    calculateShowButton() {
      if(this.showTeams) {
        return false;
      }
      return this.showTypes || this.showLocations;
    },
    calculateShowTeams() {
      if(this.showTeams) {
        return true;
      }
      return !(this.showTypes || this.showLocations)
    },

    filteredTeams() {
      if(this.selectedTypes.length == 0 && this.selectedLocations.length == 0) {
        return this.teams;
      }

      let selected = this.teams

      if(this.selectedTypes.length > 0) {
        selected = filter(selected, (team) => {
          return includes(this.selectedTypes, team.exodus_team_type_id);
        });
      }
      if(this.selectedLocations.length > 0) {
        selected = filter(selected, (team) => {
          return includes(this.selectedLocations, team.exodus_team_location_id);
        });
      }
      return selected
    }
  }
}
</script>
