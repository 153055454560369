// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import "core-js/stable";
import "regenerator-runtime/runtime";
import "picturefill";
import Vue from 'vue/dist/vue.esm.js';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { EventBus } from './event-bus';
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import "../lib/js-vido/js-vido.js";

import VScrollLock from 'v-scroll-lock'
Vue.use(VScrollLock, {
  bodyScrollOptions: {
    reserveScrollBarGap: true,
  },
})

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

// Social Sharing
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: '6LcSlL0hAAAAAFjk7FYCDVrnZdofN5TtUSlEevcq' })

if (!window.location.hostname == "localhost") {
  Sentry.init({
    dsn: 'https://d3aa9ca4698848eb808b4f367ee125e7@o44241.ingest.sentry.io/5964827',
    integrations: [new VueIntegration({Vue, attachProps: true})],
  });
}

// Register all Components Globally
const files = require.context('../components/', true, /\.vue/i)
files.keys().map(key => {
  const component = key.split('/').pop().split('.')[0]
  // With Lazy Loading
  Vue.component(component, () => import(`../components${key.replace('./','/')}`))
  // Or without Lazy Loading
  // Vue.component(component, files(key).default)
})

// Register the App
document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '[data-behavior="vue"]',
    // apolloProvider,
  });
})



document.addEventListener('DOMContentLoaded', function() {
  const eventBus = EventBus;
  const body = document.querySelector('body');
  const header = document.querySelector('header');
  const footer = document.querySelector('footer');
  const navToggles = document.querySelectorAll('.nav-toggle');
  const nav = document.querySelector('.mobile-nav');
  const modal = document.querySelector('.modal');
  let navOpen = false;

  eventBus.$on('bodyNoScroll', (payload) => {
    if(payload.scroll == 'false') {
      body.classList.add('no-scroll');
    } else {
      body.classList.remove('no-scroll');
    }
  });

  navToggles && navToggles.forEach(toggle => {
    toggle.addEventListener('click', function() {
      if(!navOpen) {
        this.classList.add('is-active');
        const navToggleIcon = document.querySelector('.nav-toggle-icon');
        navToggleIcon && navToggleIcon.classList.add('is-active');
        nav.classList.add('is-visible');
        modal && modal.classList.add('is-visible');
        body.classList.add('no-scroll');
        header.classList.add('mobile-nav-active');
        navOpen = true;
      } else {
        this.classList.remove('is-active');
        const navToggleIcon = document.querySelector('.nav-toggle-icon');
        navToggleIcon && navToggleIcon.classList.remove('is-active');
        nav.classList.remove('is-visible');
        modal && modal.classList.remove('is-visible');
        body.classList.remove('no-scroll');
        header.classList.remove('mobile-nav-active');
        navOpen = false;
      }
    });
  })

  // Mobile nav sections
  const mobileNavSections = Array.from(document.querySelectorAll('.mobile-nav__section--collapsible'));

  function closeAll() {
    mobileNavSections.forEach(section => {
      section.classList.remove('is-active');
      section.lastElementChild.style.maxHeight = 0;
    });
  }

  function setMobileNavHeights() {
    mobileNavSections.forEach((section, index) => {
      let content = section.lastElementChild;
      if(!(content.dataset.mheight && content.dataset.mheight > 0)) {
        content.dataset.mheight = content.scrollHeight
      }
    })
  }

  setMobileNavHeights()

  window.addEventListener('resize', setMobileNavHeights);

  mobileNavSections.forEach((section, index) => {
    section.addEventListener('click', function(e) {
      const content = section.lastElementChild;
      const contentHeight = content.dataset.mheight
      let isOpen = section.classList.contains('is-active');
      if(!isOpen) {
        closeAll();
        section.classList.add('is-active');
        content.style.maxHeight = contentHeight + 'px';
      } else {
        section.classList.remove('is-active');
        content.style.maxHeight = 0;
      }
    });
  });

  // Careers page video overlay
  const videoOverlay = document.querySelector('.s-careers-intro__video__overlay');
  let overlayVisible = true;

  videoOverlay && videoOverlay.addEventListener('click', function() {
    if(overlayVisible) {
      this.classList.add('is-hidden');
      overlayVisible = false;
    } else {
      this.classList.remove('is-hidden');
      overlayVisible = true;
    }
  });


  // Content Long Form Collapsible Structures
  function collapseSection(element) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;
    
    // temporarily disable all css transitions
    var elementTransition = element.style.transition;
    element.style.transition = '';
    
    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we 
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function() {
      element.style.height = sectionHeight + 'px';
      element.style.transition = elementTransition;
      
      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(function() {
        element.style.height = 0 + 'px';
      });
    });
  }
  
  function expandSection(element) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;
    
    // have the element transition to the height of its inner content
    element.style.height = sectionHeight + 'px';
  
    // when the next css transition finishes (which should be the one we just triggered)
    element.addEventListener('transitionend', function transitionExpandFinished(e) {
      // remove this event listener so it only gets triggered once
      element.removeEventListener('transitionend', transitionExpandFinished);
      
      // remove "height" from the element's inline styles, so it can return to its initial value
      element.style.height = null;
    });
  }

  const collapsibles = Array.from(document.querySelectorAll('.collapsible'));
  collapsibles.forEach((collapsible, index) => {
    var trigger = collapsible.querySelector('.collapsible--trigger');
    var section = collapsible.querySelector('.collapsible--content');
    var arrow = collapsible.querySelector('.arrow');

    trigger.addEventListener('click', function(e) {
      var isCollapsed = collapsible.getAttribute('data-collapsed') === 'true';
      if(isCollapsed) {
        expandSection(section)
        arrow.classList.remove('down')
        collapsible.setAttribute('data-collapsed', 'false')
      } else {
        collapseSection(section)
        arrow.classList.add('down')
        collapsible.setAttribute('data-collapsed', 'true')
      }
    });
  });

});

